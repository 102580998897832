import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  root: {
    position: 'sticky',
    bottom: 0,
    height: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    backgroundColor: '#EEEEEE',
  },
  typo: {
    display: 'flex',
    marginLeft: 10,
    marginRight: 20,
    color: '#78767A',
  },
})

const Footer = ({ version }) => {
  const styles = useStyles()
  const defaultGroup = useSelector(state => state?.auth?.user?.default_group?.name)

  return (
    <div
      className={styles.root}
    >
      <Typography className={styles.typo}>
        {`v${version}`}
      </Typography>
      <Typography className={styles.typo}>
        {`Sucursal ${defaultGroup}`}
      </Typography>
    </div>
  )
}

export default Footer
