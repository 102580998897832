import axios from 'axios'
import { tokenHeader } from './auth'
import store from '../store'
import { API_FAIL } from './types'
import { parseErrors } from '../misc/parseAPIErrors'
import { getPaginationTypes } from '../reducers/basePagination'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true

export const createPaginatedActions = (baseName, selector, baseUrl) => {
  const types = getPaginationTypes(baseName)
  return {
    get: async url => {
      const state = store.getState()
      const paginatedData = selector(state)
      const { selectedPage, selectedPerPage } = paginatedData
      let res
      try {
        store.dispatch({
          type: types.start,
        })
        res = await axios.get(
          url || `${baseUrl}?page_size=${selectedPerPage}&page=${selectedPage}`,
          tokenHeader(store.getState),
        )
        store.dispatch({
          type: types.success,
        })
        store.dispatch({
          type: types.get,
          payload: res.data,
        })
        return true
      } catch (error) {
        store.dispatch({
          type: API_FAIL,
          payload: parseErrors(error?.response?.data) || 'Error inesperado. Contactar con soporte',
        })
        store.dispatch({
          type: types.fail,
          payload: `Failed to fetch ${baseName} paginated data`
        })
        return false
      }
    },
    setResultsPerPage: res => {
      store.dispatch({
        type: types.setPaginationLimit,
        payload: res,
      })
    }
  }
}
