import React from 'react'
import { BsThreeDots } from 'react-icons/bs'
import './styles.css'

function MoreButton({ action, name, id }) {
  const handleClick = () => {
    action(id)
  }
  return (
    <button className="btnMore" name={name} id={id} onClick={handleClick}>
      <BsThreeDots />
    </button>
  )
}

export default MoreButton
