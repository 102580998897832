import { GET_TEAMSTERS, DELETE_TEAMSTER, ADD_TEAMSTER, EDIT_TEAMSTER } from '../actions/types'

const initialState = {
  teamsters: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
  case GET_TEAMSTERS:
    return {
      ...state,
      teamsters: action.payload,
    }
  case ADD_TEAMSTER:
    return {
      ...state,
      teamsters: [...state.teamsters, action.payload],
    }

  case DELETE_TEAMSTER:
    return {
      ...state,
      teamsters: state.teamsters.filter((teamster) => teamster.id !== action.payload),
    }

  case EDIT_TEAMSTER: {
    const index = state.teamsters.findIndex((teamster) => teamster.id === action.payload.id)
    const newArray = [...state.teamsters]
    newArray[index] = action.payload

    return {
      ...state,
      teamsters: newArray,
    }
  }

  default:
    return state
  }
}
