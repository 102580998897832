import React from 'react'
import PropTypes from 'prop-types'
import TableHeader from './TableHeader/TableHeader'
import TableBody from './TableBody/TableBody'
import './styles.css'
import { useSelector } from 'react-redux'
import PaginationBar from './PaginationBar'
import { Backdrop, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

// TODO: Update documentation
/* const exampleHeaders = [
  { title: 'ID', key: 'id' }, # Key represents the name of the field from the API
  { title: 'Name', key: 'name' },
  { title: 'Nombre Completo', key: 'full_name'},
  { title: 'Price', key: 'price' },
  { title: 'Owner', key: 'data', properties: 'address' } # this will render data under object.data.address, making nesting data easy
];

const exampleOptions = {
  selectable: true, // enables selection for each tuple
  editButton: true, // enables edit button for each tuple
  editAction: (id)=> edit(id), // send edit action, the parameter sould be id
  delButton: true, // enables delete button for each tuple
  deleteAction: (id)=> delete(id), // send edit action, the parameter sould be id
  moreButton: true, // enables more button for each tuple
  moreAction: (id)=> more(id), // send edit action, the parameter sould be id
}

****** Render is and array for rendering an specific component inside the table,
****** index is the key of the data array you want to change the render of,
****** The component will get the data inside of the value of the key as props named
****** as the key ieg: render = { index: 'group' } => <Component group={row['group']} />
****** In this example it will render the component example and the props injected
****** will be the object "completo"

const Example = ({ completo })=> <p>{`${completo.nombre} ${completo.apellido}`}</p>

// NOT TESTED
const renderExample = [
  { index: 'full_name', component: <Example /> },

// This component receives a selector function to get data from redux
// it also receives actions that are created under every db model's 
// actions.js file. This actions are used to fetch data on render and to paginate

<DataGridRedux
  headers={exampleHeaders}
  options={exampleOptions}
  selector={state => state.foo.bar.name}
  paginationActions={actions}
  paginationTypes={getTypes('STATE_DESCRIPTOR')}
/>
*/
const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 10000,
    color: '#fff',
  },
}))

export default function DataGridRedux({ options, render, headers, selector, paginationActions, paginationTypes }) {
  const styles = useStyles()
  const paginatedData = useSelector(selector)
  const rawData = paginatedData?.results
  // eslint-disable-next-line no-unused-vars
  const data = rawData.map(element => {
    const outElement = {}
    headers.forEach(header => {
      let value = `element.${header.key}.${header.properties || ''}`
      if (value.slice(-1) === '.') value = value.slice(0, -1)
      outElement[header.key] = eval(value)
    })
    return outElement
  })
  const headerKeys = headers.map(h => h.key)

  return (
    <table className="data-grid">
      <Backdrop className={styles.backdrop} open={paginatedData?.loading}>
        <CircularProgress />
      </Backdrop>
      <TableHeader
        headers={headers}
        options={options}
      />
      <TableBody
        keys={headerKeys}
        data={data}
        options={options}
        render={render}
      />
      <tfoot>
        <tr>
          <td style={{ padding: 10 }}>
            <PaginationBar selector={selector} fetchNewData={paginationActions.get} action={paginationTypes.setPaginationPage} />
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

DataGridRedux.propTypes = {
  options: PropTypes.shape({
    selectable: PropTypes.bool,
    editButton: PropTypes.bool,
    editAction: PropTypes.func,
    delButton: PropTypes.bool,
    delAction: PropTypes.func,
    moreButton: PropTypes.bool,
    moreAction: PropTypes.func,
  }).isRequired,
  render: PropTypes.arrayOf(PropTypes.object),
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  selector: PropTypes.func.isRequired,
}

DataGridRedux.defaultProps = {
  render: [],
  headers: [],
  data: [],
}
