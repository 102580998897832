/* eslint-disable max-len */
import React from 'react'

const OperatorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34.167" height="26.574" viewBox="0 0 34.167 26.574">
    <path id="operator-icon" className="icon" data-name="Icon awesome-id-card" d="M31.32,2.25H2.847A2.848,2.848,0,0,0,0,5.1v.949H34.167V5.1A2.848,2.848,0,0,0,31.32,2.25ZM0,25.977a2.848,2.848,0,0,0,2.847,2.847H31.32a2.848,2.848,0,0,0,2.847-2.847V7.944H0ZM20.88,12.215a.476.476,0,0,1,.475-.475H29.9a.476.476,0,0,1,.475.475v.949a.476.476,0,0,1-.475.475H21.354a.476.476,0,0,1-.475-.475Zm0,3.8a.476.476,0,0,1,.475-.475H29.9a.476.476,0,0,1,.475.475v.949a.476.476,0,0,1-.475.475H21.354a.476.476,0,0,1-.475-.475Zm0,3.8a.476.476,0,0,1,.475-.475H29.9a.476.476,0,0,1,.475.475v.949a.476.476,0,0,1-.475.475H21.354a.476.476,0,0,1-.475-.475ZM10.44,11.741a3.8,3.8,0,1,1-3.8,3.8A3.8,3.8,0,0,1,10.44,11.741ZM3.98,23.853a3.8,3.8,0,0,1,3.612-2.622h.486a6.11,6.11,0,0,0,4.722,0h.486A3.8,3.8,0,0,1,16.9,23.853a.934.934,0,0,1-.925,1.174H4.906A.936.936,0,0,1,3.98,23.853Z" transform="translate(0 -2.25)" fill="#f5f5f5" />
  </svg>
)

export default OperatorIcon
