import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Checkbox } from '@material-ui/core'

export const CheckBox = withStyles({
  root: {
    color: '#f58b00b0',
    '&$checked': {
      color: '#f58b00b0',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

export default CheckBox
