const initialState = {
  next: '',
  previous: '',
  current_page: 1,
  total: 0,
  per_page: 10,
  total_pages: null,
  results: [],
  selectedPerPage: 10,
  selectedPage: 1,
  loading: false,
  fail: false,
}

export const getPaginationTypes = baseName => ({
  start: `START_GET_PAGINATED_${baseName}`,
  get: `GET_PAGINATED_${baseName}`,
  loading: `LOADING_GET_PAGINATED_${baseName}`,
  success: `SUCCESS_GET_PAGINATED_${baseName}`,
  fail: `FAIL_GET_PAGINATED_${baseName}`,
  setPaginationLimit: `SET_PAGINATION_LIMIT_${baseName}`,
  setPaginationPage: `SET_PAGINATION_PAGE_${baseName}`,
})

export const createReducer = baseName => {
  const types = getPaginationTypes(baseName)
  return (state = initialState, action) => {
    switch (action.type) {
    case types.start:
      return {
        ...state,
        loading: true,
        fail: false,
      }
    case types.success:
      return {
        ...state,
        loading: false,
        fail: false,
      }
    case types.fail:
      return {
        ...state,
        loading: false,
        fail: true,
      }
    case types.get:
      return {
        ...state,
        ...action.payload,
      }
    case types.setPaginationLimit:
      return {
        ...state,
        selectedPerPage: action.payload,
        selectedPage: 1,
      }
    case types.setPaginationPage:
      return {
        ...state,
        selectedPage: action.payload,
      }
    case types.loading:
      return {
        ...state,
        loading: true,
      }
    default:
      return state
    }
  }
}

export default createReducer
