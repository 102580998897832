/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useDispatch, useSelector } from 'react-redux'
import { addLocalTruck, editLocalTruck } from '../../actions/localTrucks'

export default function TruckFormDialog({ handleClose, truck, filled, button }) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)

  const initialState = filled ? truck : {
    plate: '',
    capacity: '',
    container_type: '',
    unit: 1,
    company: 1,
    brand: '',
    group: user.groups[0],
  }

  const [truckData, setTruckData] = useState(initialState)

  const handleDataChange = (prop) => (e) => setTruckData({ ...truckData, [prop]: String(e.target.value) })
  const handleCapacityChange = (e) => setTruckData({ ...truckData, capacity: parseInt(e.target.value, 10) })

  const handlePost = () => {
    for (const [key, value] of Object.entries(truckData)) {
      if (value === '') {
        return console.error('INVALID DATA AT: ', key)
      }
    }

    if (!filled) dispatch(addLocalTruck(truckData))
    if (!button) dispatch(editLocalTruck(truckData))
    handleClose()
  }

  return (
    <>
      <Dialog open onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleClose}>
          <DialogTitle id="form-dialog-title">{!filled ? 'Crear nueva unidad' : 'Editar unidad'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {button ? 'Llene los siguientes campos para crear una nueva unidad.' : ' '}
            </DialogContentText>
            <TextField
              margin="dense"
              id="plate"
              label="No. de placas"
              type="text"
              value={truckData?.plate}
              onChange={handleDataChange('plate')}
              defaultValue={truckData?.plate}
              fullWidth
            />
            <TextField
              margin="dense"
              id="capacity"
              label="Capacidad"
              type="text"
              value={truckData?.capacity}
              onChange={handleCapacityChange}
              defaultValue={truckData?.capacity}
              // fullWidth
            />
            <Select
              labelId="demo-simple-select-label"
              id="unit"
              value={truckData?.unit}
              onChange={handleDataChange('unit')}
              defaultValue={truckData?.unit}
            >
              <MenuItem value={1}>m3</MenuItem>
              <MenuItem value={2}>Ton</MenuItem>
              <MenuItem value={3}>Kg</MenuItem>
            </Select>
            <TextField
              margin="dense"
              id="container_type"
              label="Tipo de contenedor"
              type="text"
              value={truckData?.container_type}
              onChange={handleDataChange('container_type')}
              defaultValue={truckData?.container_type}
              fullWidth
            />
            <TextField
              margin="dense"
              id="brand"
              label="Marca"
              type="text"
              value={truckData?.brand}
              onChange={handleDataChange('brand')}
              defaultValue={truckData?.brand}
              fullWidth
            />
          </DialogContent>
        </form>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
                        Cancelar
          </Button>
          <Button onClick={handlePost} color="primary">
            {!filled ? 'Crear' : 'Editar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
