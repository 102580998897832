import { Grid } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../components/common/Button'
import ServiceOrdersForm from './forms/ServiceOrdersForm'

export const NewOrder = () => {
  const history = useHistory()
  const { id } = useParams()
  let selectedOrder
  let prefilledData
  if (id) {
    selectedOrder = useSelector(state => state?.serviceOrdersList?.results)
      .find(order => order.id === parseInt(id, 10))
    prefilledData = {
      Cliente: selectedOrder?.client?.id,
      Servicio: selectedOrder?.services[0]?.service_type?.id,
      Urgent: selectedOrder?.urgency,
      Tipo: selectedOrder?.services[0]?.asset_type?.id,
      Cantidad: selectedOrder?.services[0]?.quantity,
      Responsable: selectedOrder?.in_charge_name,
      Phone: selectedOrder?.in_charge_phone,
      Referencias: selectedOrder?.location,
      Fecha: new Date(),
      Hora: '',
      Compra: selectedOrder?.purchase_order,
      Cotizacion: selectedOrder?.quotation_number,
      InfoAdicional: selectedOrder?.comments,
      Pago: selectedOrder?.payment?.method,
      NumFactura: selectedOrder?.invoice_number,
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        marginTop: 20,
        marginLeft: 20,
        height: '100%',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => history.push('/ordenes')}
            style={{ color: '#F58B00' }}
          >
            Atrás
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ServiceOrdersForm edit={!!id} prefilledData={prefilledData || {}} />
        </Grid>
      </Grid>
    </div>
  )
}

export default NewOrder
