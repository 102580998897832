import React from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import fulLogo from '../assets/static/logo_saniver_black.png'
import { login } from '../actions/auth'
import 'react-phone-input-2/lib/bootstrap.css'

const Login = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const dispatch = useDispatch()
  const handleSubmit = (values) => {
    dispatch(login(values.username, values.password))
  }

  return (
    <>
      {isAuthenticated
        ? <Redirect to="/manifiestos" />
        : (
          <div
            className="container-fluid d-flex flex-column justify-content-center align-items-center"
            style={{ backgroundColor: '#F58B00', height: '100vh', width: '100%' }}
          >
            <div
              className=""
              style={{ width: '40vh' }}
            >
              <div className="card p-4" style={{ borderRadius: '15px' }}>
                <img
                  src={fulLogo}
                  width="200px"
                  style={{ padding: 20 }}
                  className="img-fluid mx-auto d-block"
                  alt="Logo Saniver"
                />
                <Formik
                  initialValues={{ username: '', password: '' }}
                  validationSchema={
                    Yup.object().shape({
                      username: Yup.string().required('Introduzca su usario'),
                      password: Yup.string().required('Introduzca su contraseña'),
                    })
                  }
                  onSubmit={handleSubmit}
                  validateOnChange
                  validateOnMount
                >
                  {props => (
                    <Form className="d-flex flex-column">
                      <div className="form-group">
                        <Field type="text" name="username" className="form-control" placeholder="Usuario" />
                        <ErrorMessage name="username" component="div" className="field-error text-danger" />
                      </div>
                      <div className="form-group">
                        <Field type="password" name="password" className="form-control" placeholder="Contraseña" />
                        <ErrorMessage name="password" component="div" className="field-error text-danger" />
                      </div>
                      <button type="submit" disabled={!props.isValid} className="btn btn-info">Entrar</button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        )}
    </>
  )
}

export default Login
