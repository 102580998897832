import React from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  input: {
    color: '#2F8CCA',
  },
}))

export const TextInput = (props) => {
  const styles = useStyles()
  return (
    <TextField className={styles.input} variant="outlined" {...props} />
  )
}

export default TextInput

TextInput.defaultProps = {
  margin: 'dense',
}
