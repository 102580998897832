import React from 'react'
import PropTypes from 'prop-types'
import { DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { useSelector } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Button } from '../../components/common/Button'
import DialogBlur from '../../components/common/DialogBlur'
import { TextInput } from '../../components/common/TextInput'
import { choices } from './stateChoices'
import { addTeamster, editTeamster, getTeamsters } from '../../actions/teamsters'

const useStyles = makeStyles(() => ({
  errorText: {
    color: '#ff3f14',
  },
  dialogTitle: {
    backgroundColor: '#F58B00',
    color: 'white',
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
  },
  content: {
    dividers: true,
    overflow: 'hidden',
    marginTop: 20,
    marginBottom: 20,
  },
}))

const TeamsterFormDialog = (props) => {
  const styles = useStyles()
  const { teamster, edit, open, setOpen } = props
  const defaultGroup = useSelector(state => state?.auth?.user?.default_group?.id)
  const handleSubmit = async values => {
    const postValues = {
      ...values,
      group: defaultGroup,
      company: 1,
      user: null,
    }
    let res
    if (!edit) {
      res = await addTeamster(postValues)
    }
    else {
      res = await editTeamster(postValues)
    }
    if (res) {
      await getTeamsters()
      setOpen(false)
    }
  }
  const initialState = edit ? {
    ...teamster,
    user: teamster?.user?.id,
    group: teamster?.group?.id,
  } : {
    name: '',
    location: '',
    group: defaultGroup,
  }

  return (
    <DialogBlur
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={() => setOpen(false)}
    >
      <DialogTitle className={styles.dialogTitle}>
        {edit ? `Editar Operador: ${teamster?.name}` : 'Nuevo Operador'}
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Formik
          initialValues={initialState}
          validationSchema={
            yup.object({
              name: yup.string('Nombre completo').required('Este campo es requerido'),
              location: yup.string('Ubicacion').required('Este campo es requerido'),
              user: yup.number('Usuario'),
              ssn: yup.string('NSS')
                .min(11, 'El numero debe tener 11 dígitos')
                .max(11, 'El numero puede tener máximo 11 dígitos')
                .required('Este campo es requerido'),
            })
          }
          onSubmit={handleSubmit}
          validateOnChange
          validateOnMount
        >
          {formikProps => (
            <Form
              onSubmit={formikProps.handleSubmit}
              onBlur={formikProps.handleBlur}
              noValidate
            >
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xs={12}>
                  <TextInput
                    name="name"
                    label="Nombre completo"
                    value={formikProps.values.name}
                    onChange={formikProps.handleChange}
                    error={formikProps.errors.name && formikProps.touched.name}
                    helperText={formikProps.touched.name && formikProps.errors.name}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    name="ssn"
                    label="Número de seguro social"
                    value={formikProps.values.ssn}
                    onChange={formikProps.handleChange}
                    error={formikProps.errors.ssn && formikProps.touched.ssn}
                    helperText={formikProps.touched.ssn && formikProps.errors.ssn}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    name="location"
                    options={choices}
                    getOptionLabel={o => choices.find(c => c.value === o)?.displayName || o?.displayName}
                    onChange={(e, { value }) => {
                      formikProps.setValues(values => ({ ...values, location: value }))
                    }}
                    value={formikProps.values.location}
                    fullWidth
                    renderInput={params => (
                      <TextInput
                        {...params}
                        label="Estado"
                        name="location"
                        error={formikProps.errors.location && formikProps.touched.location}
                        helperText={formikProps.touched.location && formikProps.errors.location}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    type="submit"
                    disabled={!formikProps?.isValid}
                  >
                    {edit ? 'Guardar' : 'Crear'}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </DialogBlur>
  )
}

export default TeamsterFormDialog

TeamsterFormDialog.propTypes = {
  teamster: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  edit: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

TeamsterFormDialog.defaultProps = {
  teamster: {},
}
