import React, { useState, useEffect } from 'react'
// import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
// import ManifestoFormDialog from '../containers/forms/ManifestoFormDialog'
import Button from '../components/common/Button'
import NimbusTable from '../components/DataGridRedux/DataGridRedux'
import SearchBar from '../components/Search/SearchBar'
import { paginatedActions as actions } from '../actions/manifestos'
import { getPaginationTypes } from '../reducers/basePagination'

const Manifestos = () => {
  // const [open, setOpen] = useState(false)
  // const [selectedManif, setSelectedManif] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  // const manifestos = useSelector(state => state?.manifestosList?.results)

  const headers = [
    { title: 'ID', key: 'id' },
    { title: 'Folio', key: 'folio' },
    { title: 'Embarque', key: 'ship_date'},
    { title: 'Cliente', key: 'client', properties: 'name' },
  ]

  // const handleClickOpen = id => {
  //   setSelectedManif(manifestos.find(serviceOrder => serviceOrder.id === id))
  //   setOpen(true)
  // }

  const options = {
    selectable: false,
    editButton: true,
    // editAction: id => handleClickOpen(id),
    delButton: false,
    moreButton: false,
  }

  useEffect(() => {
    actions.get()
  }, [])

  return (
    <Grid container justifyContent="space-between" spacing={3} style={{ paddingLeft: 20, paddingTop: 20 }}>
      <Grid item xs={6}>
        <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
        >
          Nuevo manifiesto
        </Button>
      </Grid>
      <Grid
        item xs={12}
        style={{
          height: '80vh',
          overflowY: 'scroll',
          boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
          borderRadius: '20px 20px 0 0',
          padding: 0,
          margin: '12px',
        }}
      >
        <NimbusTable
          headers={headers}
          options={options}
          selector={state => state?.manifestosList}
          paginationActions={actions}
          paginationTypes={getPaginationTypes('MANIFESTOS')}
        />
        {/* <ManifestoFormDialog
          openRender={open}
          manifesto={selectedManif}
          filled={!!selectedManif}
          button={!!selectedManif}
          handleClose={() => setOpen(false)}
        /> */}
      </Grid>
    </Grid>
  )
}

export default Manifestos
