import React from 'react'
import '../assets/styles/components/Main.scss'

const Main = ({ children }) => (
  <div className="p-0 m-0" style={{ height: '100%' }}>
    { children }
  </div>
)

export default Main
