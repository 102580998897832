import { DELETE_CLIENT, GET_CLIENTS, ADD_CLIENT, EDIT_CLIENT } from '../actions/types'

const initialState = {
  clients: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
  case GET_CLIENTS:
    return {
      ...state,
      clients: action.payload,
    }
  case DELETE_CLIENT:
    return {
      ...state,
      clients: state.clients.filter((client) => client.id !== action.payload),
    }
  case ADD_CLIENT:
    return {
      ...state,
      clients: [...state.clients, action.payload],
    }
  case EDIT_CLIENT: {
    const index = state.clients.findIndex((client) => client.id === action.payload.id)
    console.log(index)

    const newArray = [...state.clients]
    newArray[index] = action.payload

    return {
      ...state,
      clients: newArray,
    }
  }

  default:
    return state
  }
}
