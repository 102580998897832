import { GET_DISPOSAL_TYPES, ADD_DISPOSAL_TYPE } from '../actions/types'

const initialState = {
  disposalTypes: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
  case GET_DISPOSAL_TYPES:
    return {
      ...state,
      disposalTypes: action.payload,
    }
  case ADD_DISPOSAL_TYPE:
    return {
      ...state,
      disposalTypes: [...state.disposalTypes, action.payload],
    }
  default:
    return state
  }
}
