import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import ServiceOrderDialog from './forms/ServiceOrderDialog'
import { useHistory } from 'react-router'
import Button from '../components/common/Button'
import NimbusTable from '../components/DataGridRedux/DataGridRedux'
import SearchBar from '../components/Search/SearchBar'
import { paginatedActions as actions } from '../actions/serviceOrders'
import { getPaginationTypes } from '../reducers/basePagination'

const ServiceOrder = () => {
  const [open, setOpen] = useState(false)
  const [selectedServiceOrder, setSelectedServiceOrder] = useState(null)
  const history = useHistory()
  const [searchValue, setSearchValue] = useState('')
  const serviceOrders = useSelector(state => state?.serviceOrdersList?.results)

  const headers = [
    { title: 'ID', key: 'id' },
    { title: 'Cliente', key: 'client', properties: 'name' },
  ]

  const handleClickOpen = id => {
    setSelectedServiceOrder(serviceOrders.find(serviceOrder => serviceOrder.id === id))
    setOpen(true)
  }

  const options = {
    selectable: false,
    editButton: true,
    editAction: id => history.push(`/ordenes/editar/${id}`),
    delButton: false,
    moreButton: true,
    moreAction: id => handleClickOpen(id)
  }

  useEffect(() => {
    actions.get()
  }, [])

  return (
    <Grid container justifyContent="space-between" spacing={3} style={{ paddingLeft: 20, paddingTop: 20 }}>
      <Grid item xs={6}>
        <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          onClick={() => history.push('/ordenes/nueva')}
        >
          Nueva orden
        </Button>
      </Grid>
      <Grid
        item xs={12}
        style={{
          height: '80vh',
          overflowY: 'scroll',
          boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
          borderRadius: '20px 20px 0 0',
          padding: 0,
          margin: '12px',
        }}
      >
        <NimbusTable
          headers={headers}
          options={options}
          selector={state => state?.serviceOrdersList}
          paginationActions={actions}
          paginationTypes={getPaginationTypes('SERVICE_ORDERS')}
        />
      </Grid>
      <ServiceOrderDialog
        open={open}
        onClose={() => setOpen(false)}
        data={selectedServiceOrder}
      />
    </Grid>
  )
}

export default ServiceOrder
