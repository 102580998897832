import { styled } from '@material-ui/core/styles'
import MaterialButton from '@material-ui/core/Button'

export const Button = styled(MaterialButton)({
  color: '#2F8CCA',
  borderRadius: 20,
  fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
})

export default Button
