import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const initialState = {}

let middleware = applyMiddleware(...[thunk])
if (process.env.NODE_ENV !== 'production') middleware = composeWithDevTools(middleware)

const store = createStore(
  rootReducer,
  initialState,
  middleware,
)

export default store
