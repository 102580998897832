export const parseErrors = errors => {
  let parsedErrors = ''
  if (errors === null) return
  if (typeof errors === 'object' && !Array.isArray(errors)) {
    Object.values(errors).forEach(value => {
      if (Array.isArray(value)) value.forEach(v => parsedErrors = parsedErrors.concat(' ', v))
      else if (typeof value === 'string') parsedErrors = parsedErrors.concat(' ', value)
    })
  }
  else if (Array.isArray(errors)) {
    errors.forEach(e => {
      Object.values(e).forEach(v => parsedErrors = parsedErrors.concat(' ', v))
    })
  }
  return parsedErrors
}
