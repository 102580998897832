import React from 'react'
import PropTypes from 'prop-types'
import Pagination from '@material-ui/lab/Pagination'
import { useDispatch, useSelector } from 'react-redux'

const PaginationBar = props => {
  const { selector, fetchNewData, action } = props
  console.log(props)
  const dispatch = useDispatch()
  const paginationData = useSelector(selector)
  return (
    <Pagination
      count={paginationData?.total_pages}
      style={{ backgroundColor: 'white !important'}}
      page={paginationData?.selectedPage}
      onChange={(e, value) => {
        dispatch({
          type: action,
          payload: parseInt(value, 10)
        })
        fetchNewData()
      }}
    />
  )
}

export default PaginationBar

PaginationBar.propTypes = {
  selector: PropTypes.func.isRequired,
  fetchNewData: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
}
