import axios from 'axios'
import { USER_LOADED, USER_LOADING, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS } from './types'

// SET TOKEN HEADER HELPER FUNC
export const tokenHeader = (getState) => {
  const { token } = getState().auth

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  if (token) {
    config.headers.Authorization = `Token ${token}`
  }

  return config
}

// SET TOKEN HEADER HELPER FUNC
export const tokenHeaderT = (token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  if (token) {
    config.headers.Authorization = `Token ${token}`
  }

  return config
}

// CHECK TOKEN AND LOAD USER
export const loadUser = () => (dispatch, getState) => {
  dispatch({ type: USER_LOADING })

  axios
    .get('/api/auth/user', tokenHeader(getState))
    .then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      })
    })
    .catch(() => {
      dispatch({ type: AUTH_ERROR })
    })
}

// LOGIN USER
export const login = (username, password) => (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  // Request body
  const body = JSON.stringify({ username, password })

  axios
    .post('/api/auth/login', body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      })
    })
    .catch(() => {
      dispatch({
        type: LOGIN_FAIL,
        payload: 'Usuario o contraseña incorrectos.',
      })
    })
}

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
  axios
    .post('/api/auth/logout/', null, tokenHeader(getState))
    .then(() => {
      dispatch({
        type: LOGOUT_SUCCESS,
      })
    })
    .catch((err) => {
      console.error(err)
    })
}
