import axios from 'axios'
import { parseErrors } from '../misc/parseAPIErrors'
import store from '../store'
import { tokenHeader } from './auth'
import { createPaginatedActions } from './basePaginatedActions'
import { GET_CLIENTS, DELETE_CLIENT, ADD_CLIENT, EDIT_CLIENT, API_SUCCESS, API_FAIL } from './types'

// GET CLIENTS
export const getClients = async () => {
  let res
  try {
    res = await axios.get('/api/clients/', tokenHeader(store.getState))
    store.dispatch({
      type: GET_CLIENTS,
      payload: res.data,
    })
    return true
  } catch (error) {
    store.dispatch({
      type: API_FAIL,
      payload: 'Error al cargar clientes. Contacte a soporte'
    })
    return false
  }
}

// DELETE CLIENTS
export const deleteClient = (id) => (dispatch, getState) => {
  axios
    .delete(`/api/clients/${id}/`, tokenHeader(getState))
    .then(() => {
      dispatch({
        type: DELETE_CLIENT,
        payload: id,
      })
    })
    .catch((err) => console.error(err))
}

// ADD CLIENT
export const addClient = async client => {
  let res
  try {
    res = await axios.post('/api/clients/', client, tokenHeader(store.getState))
    store.dispatch({
      type: ADD_CLIENT,
      payload: res.data,
    })
    store.dispatch({
      type: API_SUCCESS,
      payload: 'Cliente creado correctamente',
    })
    return true
  } catch (error) {
    store.dispatch({
      type: API_FAIL,
      payload: parseErrors(error?.response?.data) || 'Error desconocido. Contacte a soporte'
    })
    return false
  }
}

// EDIT CLIENT
export const editClient = async (client, id) => {
  let res
  try {
    res = await axios.put(`/api/clients/${id}/`, client, tokenHeader(store.getState))
    store.dispatch({
      type: EDIT_CLIENT,
      payload: res.data,
    })
    store.dispatch({
      type: API_SUCCESS,
      payload: 'Cliente guardado correctamente',
    })
    return true
  } catch (error) {
    store.dispatch({
      type: API_FAIL,
      payload: parseErrors(error?.response?.data) || 'Error desconocido. Contacte a soporte'
    })
    return false
  }
}

// PAGINATED ACTIONS
export const paginatedActions = createPaginatedActions(
  'CLIENTS',
  state => state.clientsList,
  '/api/clients_list/',
)
