export const choices = [
  {
    value: 'AGS',
    displayName: 'AGUASCALIENTES',
  },
  {
    value: 'BC',
    displayName: 'BAJA CALIFORNIA',
  },
  {
    value: 'BCS',
    displayName: 'BAJA CALIFORNIA SUR',
  },
  {
    value: 'CHI',
    displayName: 'CHIHUAHUA',
  },
  {
    value: 'CHS',
    displayName: 'CHIAPAS',
  },
  {
    value: 'CMP',
    displayName: 'CAMPECHE',
  },
  {
    value: 'CMX',
    displayName: 'CIUDAD DE MEXICO',
  },
  {
    value: 'COA',
    displayName: 'COAHUILA',
  },
  {
    value: 'COL',
    displayName: 'COLIMA',
  },
  {
    value: 'DGO',
    displayName: 'DURANGO',
  },
  {
    value: 'GRO',
    displayName: 'GUERRERO',
  },
  {
    value: 'GTO',
    displayName: 'GUANAJUATO',
  },
  {
    value: 'HGO',
    displayName: 'HIDALGO',
  },
  {
    value: 'JAL',
    displayName: 'JALISCO',
  },
  {
    value: 'MCH',
    displayName: 'MICHOACAN',
  },
  {
    value: 'MEX',
    displayName: 'ESTADO DE MEXICO',
  },
  {
    value: 'MOR',
    displayName: 'MORELOS',
  },
  {
    value: 'NAY',
    displayName: 'NAYARIT',
  },
  {
    value: 'NL',
    displayName: 'NUEVO LEON',
  },
  {
    value: 'OAX',
    displayName: 'OAXACA',
  },
  {
    value: 'PUE',
    displayName: 'PUEBLA',
  },
  {
    value: 'QR',
    displayName: 'QUINTANA ROO',
  },
  {
    value: 'QRO',
    displayName: 'QUERETARO',
  },
  {
    value: 'SIN',
    displayName: 'SINALOA',
  },
  {
    value: 'SLP',
    displayName: 'SAN LUIS POTOSI',
  },
  {
    value: 'SON',
    displayName: 'SONORA',
  },
  {
    value: 'TAB',
    displayName: 'TABASCO',
  },
  {
    value: 'TLX',
    displayName: 'TLAXCALA',
  },
  {
    value: 'TMS',
    displayName: 'TAMAULIPAS',
  },
  {
    value: 'VER',
    displayName: 'VERACRUZ',
  },
  {
    value: 'YUC',
    displayName: 'YUCATAN',
  },
  {
    value: 'ZAC',
    displayName: 'ZACATECAS',
  },
]
