import axios from 'axios'
import store from '../store'
import { tokenHeader } from './auth'
import { API_FAIL, GET_SERVICE_TYPES } from './types'

export const getServiceTypes = async () => {
  let res
  try {
    res = await axios.get('/api/service_types', tokenHeader(store.getState))
    store.dispatch({
      type: GET_SERVICE_TYPES,
      payload: res?.data,
    })
  } catch (error) {
    store.dispatch({
      type: API_FAIL,
      payload: String(error?.response?.data) || 'Unknown error',
    })
  }
}
