import { GET_CONTAINERS, ADD_CONTAINER } from '../actions/types'

const initialState = {
  containers: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
  case GET_CONTAINERS:
    return {
      ...state,
      containers: action.payload,
    }
  case ADD_CONTAINER:
    return {
      ...state,
      containers: [...state.containers, action.payload],
    }
  default:
    return state
  }
}
