import { GET_SERVICE_TYPES } from '../actions/types'

const initialState = {
  elements: [],
  loading: false,
  lastFetch: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
  case GET_SERVICE_TYPES:
    return {
      ...state,
      elements: action.payload,
    }
  default:
    return state
  }
}
