import React from 'react'
import '../assets/styles/components/Header.scss'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../assets/static/logo_saniver_letras_blancas.png'
import { logout } from '../actions/auth'

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    color: 'black',
  },
}))

const Header = () => {
  const user = useSelector(state => state?.auth?.user)
  const initials = `${user?.first_name.charAt(0)}${user?.last_name.charAt(0)}`
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <nav className="barra">
      <Link to="/">
        <img className="logo" src={logo} alt="logo-saniver" />
      </Link>

      <div className="user-menu" onClick={handleClick}>
        {user?.profile_picture
          ? (
            <div
              style={{
                display: 'inline-block',
                backgroundImage: `url(${user.profile_picture})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                zIndex: 10000,
                backgroundColor: 'black',
              }}
            />
          )
          : <h3 className="user-initials">{initials}</h3>}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <a onClick={handleLogout} style={{ cursor: 'pointer' }}>
          <Typography className={classes.typography}>Cerrar sesión</Typography>
        </a>
      </Popover>
    </nav>
  )
}

export default Header
