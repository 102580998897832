/* eslint-disable max-len */
import React from 'react'

const ClientIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="39.574" height="30.356" viewBox="0 0 39.574 30.356">
    <path id="client-icon" className="icon" data-name="Icon zocial-persona" d="M16.24,23.839a74.1,74.1,0,0,1-8.2-.278s.321-4.9,2.562-5.778,2.79-.757,3.566-1.226l.058-.032V15.206a6.073,6.073,0,0,1-1.688-2.767,2.188,2.188,0,0,1-.638-1.493,2.033,2.033,0,0,1,.311-1.062c.133-2.761,1.889-4.589,4.038-4.589s3.907,1.827,4.038,4.589a2.028,2.028,0,0,1,.311,1.062,2.188,2.188,0,0,1-.638,1.493,6.06,6.06,0,0,1-1.688,2.766v1.319c.02.01.039.021.057.032.779.469,1.327.344,3.568,1.226s2.56,5.778,2.56,5.778a74.487,74.487,0,0,1-8.215.278M26.558-.035H1.439A1.445,1.445,0,0,0-.006,1.411V28.878a1.444,1.444,0,0,0,1.445,1.444H26.558L39.567,15.145,26.558-.035Z" transform="translate(0.006 0.035)" fill="#f5f5f5" />
  </svg>
)

export default ClientIcon
