import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../components/common/Button'
import NimbusTable from '../components/DataGridRedux/DataGridRedux'
import SearchBar from '../components/Search/SearchBar'
import { getPaginationTypes } from '../reducers/basePagination'
import ClientFormDialog from './forms/ClientFormDialog'
import { paginatedActions as actions } from '../actions/clients'

export const Clients = () => {
  const [searchValue, setSearchValue] = useState('')
  const clients = useSelector(state => state?.clientsList?.results)
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(false)

  const headers = [
    { title: 'ID', key: 'id' },
    { title: 'Nombre', key: 'name' },
    { title: 'Razón Social', key: 'legal_form' },
    { title: 'En cargo', key: 'is_in_charge' },
  ]

  const options = {
    selectable: false,
    editButton: true,
    editAction: id => {
      const selectedC = clients.find(c => c?.id === parseInt(id, 10))
      setSelected(selectedC)
      setOpen(true)
    },
    delButton: false,
    moreButton: false,
  }

  useEffect(() => {
    actions.get()
  }, [])

  return (
    <Grid container justifyContent="space-between" spacing={3} style={{ paddingLeft: 20, paddingTop: 20 }}>
      <ClientFormDialog
        open={open}
        setOpen={setOpen}
        mode={selected ? 'edit' : 'create'}
        client={selected}
        id={selected ? selected?.id : null}
      />
      <Grid item xs={6}>
        <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          onClick={() => {
            setSelected(false)
            setOpen(true)
          }}
        >
          Nuevo cliente
        </Button>
      </Grid>
      <Grid
        item xs={12}
        style={{
          height: '80vh',
          overflowY: 'scroll',
          boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
          borderRadius: '20px 20px 0 0',
          padding: 0,
          margin: '12px',
        }}
      >
        <NimbusTable
          headers={headers}
          options={options}
          selector={state => state?.clientsList}
          paginationActions={actions}
          paginationTypes={getPaginationTypes('CLIENTS')}
        />
      </Grid>
    </Grid>
  )
}

export default React.memo(Clients)
