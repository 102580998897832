import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../components/common/Button'
import NimbusTableRedux from '../components/DataGridRedux/DataGridRedux'
import SearchBar from '../components/Search/SearchBar'
import TeamsterFormDialog from './forms/TeamsterFormDialog'
import { paginatedActions as actions } from '../actions/teamsters'
import { getPaginationTypes } from '../reducers/basePagination'

const Teamsters = () => {
  useEffect(() => {
    actions.get()
  }, [])

  const [searchValue, setSearchValue] = useState('')
  const teamsters = useSelector(state => state?.teamstersList?.results)
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(false)

  const headers = [
    { title: 'ID', key: 'id' },
    { title: 'Nombre', key: 'name' },
    { title: 'NSS', key: 'ssn' },
    { title: 'Empresa', key: 'company', properties: 'name' },
    { title: 'Sede', key: 'group', properties: 'name' },
  ]

  const options = {
    selectable: false,
    editButton: true,
    editAction: id => {
      const selectedT = teamsters.find(c => c?.id === parseInt(id, 10))
      setSelected(selectedT)
      setOpen(true)
    },
    delButton: false,
    moreButton: true,
  }

  return (
    <Grid container justifyContent="space-between" spacing={3} style={{ paddingLeft: 20, paddingTop: 20 }}>
      <TeamsterFormDialog
        open={open}
        setOpen={setOpen}
        edit={!!selected}
        teamster={selected}
      />
      <Grid item xs={6}>
        <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          onClick={() => setOpen(true)}
        >
          Nuevo operador
        </Button>
      </Grid>
      <Grid
        item xs={12}
        style={{
          height: '80vh',
          overflowY: 'scroll',
          boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
          borderRadius: '20px 20px 0 0',
          padding: 0,
          margin: '12px',
        }}
      >
        <NimbusTableRedux
          headers={headers}
          options={options}
          selector={state => state.teamstersList}
          paginationActions={actions}
          paginationTypes={getPaginationTypes('TEAMSTERS')}
        />
      </Grid>
    </Grid>
  )
}

export default Teamsters
