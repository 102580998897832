import { API_FAIL, API_SUCCESS, CLOSE_SNACKBAR, LOGIN_FAIL, LOGIN_SUCCESS } from '../actions/types'

const initialState = {
  open: false,
  message: '',
  severity: 'info',
  openSeconds: 5000,
  vertical: 'top',
  horizontal: 'right',
}

export default function (state = initialState, action) {
  switch (action.type) {
  case LOGIN_SUCCESS:
    return {
      ...state,
      open: true,
      message: `¡Bienvenid@, ${action.payload?.user?.first_name}!`,
      severity: 'success',
      vertical: 'bottom',
      horizontal: 'center',
    }
  case API_SUCCESS:
    return {
      ...state,
      open: true,
      message: action.payload,
      severity: 'success',
      vertical: 'top',
      horizontal: 'right',
    }
  case API_FAIL:
  case LOGIN_FAIL:
    return {
      ...state,
      open: true,
      message: action.payload,
      severity: 'error',
      vertical: 'top',
      horizontal: 'right',
    }
  case CLOSE_SNACKBAR:
    return {
      ...state,
      open: false,
    }
  default:
    return state
  }
}
