import { render } from 'react-dom'
import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Provider } from 'react-redux'
import axios from 'axios'
import store from './store'
import App from './routes/App'
import { API_FAIL } from './actions/types'

let csrfToken = null
const baseUrl = process.env.REACT_APP_BASE_URL
axios.defaults.baseURL = baseUrl

const getCSRF = async () => {
  let response
  let data
  try {
    response = await axios.get('/api/csrf')
    data = response.data
    csrfToken = data?.csrfToken
  } catch (err) {
    store.dispatch({
      type: API_FAIL,
      payload: 'ERROR. Contacte a soporte: CSRF NOT LOADED'
    })
  }
}

getCSRF()
axios.defaults.headers.common['X-CSRFToken'] = csrfToken
axios.defaults.withCredentials = false

const appDiv = document.getElementById('app')
render(
  <Provider store={store}>
    <CssBaseline />
    <App />
  </Provider>,
  appDiv,
)
