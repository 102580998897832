import axios from 'axios'
import { ADD_SERVICE_ORDER, API_FAIL, API_SUCCESS, GET_SERVICE_ORDERS } from './types'
import { tokenHeader } from './auth'
import store from '../store'
import { parseErrors } from '../misc/parseAPIErrors'
import { createPaginatedActions } from './basePaginatedActions'

// GET SERVICE ORDERS
export const getServiceOrders = async () => {
  let res
  try {
    res = await axios.get('/api/service_orders/', tokenHeader(store.getState))
    store.dispatch({
      type: GET_SERVICE_ORDERS,
      payload: res?.data,
    })
    return true
  } catch (error) {
    store.dispatch({
      type: API_FAIL,
      payload: 'Error al cargar ordenes de servicio.'
    })
    return false
  }
}

export const createServiceOrder = async data => {
  let res
  try {
    res = await axios.post('/api/service_orders/', data, tokenHeader(store.getState))
    store.dispatch({
      type: ADD_SERVICE_ORDER,
      payload: res.data,
    })
    store.dispatch({
      type: API_SUCCESS,
      payload: 'Orden creada',
    })
    return true
  } catch (error) {
    store.dispatch({
      type: API_FAIL,
      payload: parseErrors(error?.response?.data) || 'Error al crear orden'
    })
    return false
  }
}

// PAGINATED ACTIONS
export const paginatedActions = createPaginatedActions(
  'SERVICE_ORDERS',
  state => state.serviceOrdersList,
  '/api/service_orders_list/',
)
