import { GET_MANIFESTOS, DELETE_MANIFESTO, ADD_MANIFESTO, EDIT_MANIFESTO } from '../actions/types'

const initialState = {
  manifestos: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
  case GET_MANIFESTOS:
    return {
      ...state,
      manifestos: action.payload,
    }
  case ADD_MANIFESTO:
    return {
      ...state,
      manifestos: [...state.manifestos, action.payload],
    }

  case DELETE_MANIFESTO:
    return {
      ...state,
      manifestos: state.manifestos.filter((manifesto) => manifesto.id !== action.payload),
    }

  case EDIT_MANIFESTO: {
    const index = state.manifestos.findIndex((manifesto) => manifesto.id === action.payload.id)

    const newArray = [...state.manifestos]
    newArray[index] = action.payload

    return {
      ...state,
      manifestos: newArray,
    }
  }

  default:
    return state
  }
}
