import axios from 'axios'
import { GET_LOCAL_TRUCKS, DELETE_LOCAL_TRUCK, ADD_LOCAL_TRUCK, EDIT_LOCAL_TRUCK } from './types'
import { tokenHeader } from './auth'
// axios.defaults.xsrfHeaderName = "X-CSRFToken";
// axios.defaults.withCredentials = true;

// GET LOCAL TRUCKS
export const getLocalTrucks = () => (dispatch, getState) => {
  axios
    .get('api/local_trucks/', tokenHeader(getState))
    .then((res) => {
      dispatch({
        type: GET_LOCAL_TRUCKS,
        payload: res.data,
      })
    })
    .catch((err) => console.error(err))
}

// ADD LOCAL TRUCK
export const addLocalTruck = (truck) => (dispatch, getState) => {
  axios
    .post('/api/local_trucks/', truck, tokenHeader(getState))
    .then((res) => {
      dispatch({
        type: ADD_LOCAL_TRUCK,
        payload: res.data,
      })
    })
    .catch((err) => console.error(err))
}

// DELETE LOCAL TRUCK
export const deleteLocalTruck = (id) => (dispatch, getState) => {
  axios
    .delete(`/api/local_trucks/${id}/`, tokenHeader(getState))
    .then(() => {
      dispatch({
        type: DELETE_LOCAL_TRUCK,
        payload: id,
      })
    })
    .catch((err) => console.error(err))
}

// EDIT LOCAL TRUCK
export const editLocalTruck = (truck) => (dispatch, getState) => {
  axios
    .put(`/api/local_trucks/${truck.id}/`, truck, tokenHeader(getState))
    .then((res) => {
      dispatch({
        type: EDIT_LOCAL_TRUCK,
        payload: res.data,
      })
    })
    .catch((err) => console.error(err))
}
