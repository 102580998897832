import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import { useDispatch, useSelector } from 'react-redux'
import Main from '../components/Main'
import TruckFormDialog from './forms/TruckFormDialog'

// Redux
import { deleteLocalTruck, getLocalTrucks } from '../actions/localTrucks'

const Unidades = () => {
  const localTrucks = useSelector((state) => state.localTrucks.localTrucks)
  const dispatch = useDispatch()
  const [newTruck, setNew] = useState(false)
  const [editTruck, setEdit] = useState(false)
  const [truckInfo, setTruck] = useState({})

  const handleDelete = (id) => {
    dispatch(deleteLocalTruck(id))
  }

  useEffect(() => {
    dispatch(getLocalTrucks())
  }, [])


  const unitPrint = (unit) => {
    if (unit === 1) return 'm3'
    if (unit === 2) return 'Ton'
    if (unit === 3) return 'Kg'
  }

  const truckFilter = (id) => {
    const truck = localTrucks.find((c) => c.id === id)
    return truck
  }

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'plate', headerName: 'No. de placas', flex: 1 },
    { field: 'capacity', headerName: 'Capacidad', flex: 1 },
    { field: 'containerType', headerName: 'Tipo de contenedor', flex: 1 },
    { field: 'brand', headerName: 'Marca', flex: 1 },
    {
      field: 'edit',
      headerName: ' ',
      sortable: false,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const { api } = params
        const thisRow = {}

        const handleClick = (e) => {
          e.stopPropagation()
          api.getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach((c) => {
              thisRow[c.field] = params.getValue(
                params.id,
                c.field,
              )
            })
          setTruck(truckFilter(thisRow.id))
          setEdit(true)
        }

        return <Button onClick={handleClick}>Edit</Button>
      },
    },
    {
      field: 'delete',
      headerName: ' ',
      sortable: false,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation()
          const { api } = params
          const thisRow = {}
          api.getAllColumns()
            .filter((c) => c !== '__check__' && !!c)
            .forEach((c) => {
              thisRow[c.field] = params.getValue(
                params.id,
                c.field,
              )
            })
          const truck = truckFilter(thisRow.id)
          handleDelete(truck.id)
        }

        return <Button onClick={onClick}>Eliminar</Button>
      },
    },
  ]

  return (

    <Main>
      {newTruck ? (
        <TruckFormDialog
          description="hola"
          button={false}
          filled={false}
          handleClose={() => setNew(false)}
        />
      )
        : null}

      {editTruck ? (
        <TruckFormDialog
          description="hola"
          button={false}
          filled
          truck={truckInfo}
          handleClose={() => setEdit(false)}
        />
      )
        : null}
      <div className="p-5 text-right" style={{ height: '75%' }}>
        <DataGrid
          rows={localTrucks.map((truck) => ({
            id: truck.id,
            plate: truck.plate,
            capacity: `${truck.capacity} ${unitPrint(truck.unit)}`,
            containerType: truck.container_type,
            brand: truck.brand,
            edit: 'edit',
            delete: 'delete',
          }))}
          columns={columns}
          pageSize={10}
          checkboxSelection
        />
        <button
          className="btn mt-4"
          style={{
            backgroundColor: '#34D06A',
            color: '#fff',
            fontSize: '18px',
          }}
          onClick={() => setNew(true)}
        >
				Nuevo
        </button>
      </div>
    </Main>

  )
}

export default Unidades
