import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AiFillCheckCircle } from 'react-icons/ai'
import { CgUnavailable } from 'react-icons/cg'
import NimbusTable from '../components/DataGrid/DataGrid'

const TestTable = () => {
  const token = useSelector(state => state.auth.token)
  const [data, setData] = useState([])

  useEffect(async () => {
    let res
    try {
      res = await axios.get('/api/assets/?asset_type__key=truck', { headers: { Authorization: `Token ${token}` } })
      const processed = res.data.map(asset => ({
        serial_number: asset.serial_number,
        name: asset.name,
        type: asset.asset_type.name,
        available: asset.available,
      }))
      setData(processed)
      console.log('API RESPONSE:')
      console.log(res.data)
      console.log('MAPPED DATA:')
      console.log(processed)
    } catch (error) {
      console.error(error)
    }
  }, [token])

  const headers = [
    { title: 'Serial Number', key: 'serial_number' },
    { title: 'Nombre', key: 'name' },
    { title: 'Tipo', key: 'type' },
    { title: 'Disponible', key: 'available' },
  ]

  const exampleOptions = {
    selectable: false,
    editButton: true,
    delButton: true,
    moreButton: true,
  }
  const Example = ({ available }) => (
    available
      ? <AiFillCheckCircle color="green" size={30} />
      : <CgUnavailable color="red" size={30} />
  )

  const renderExample = [
    { index: 'available', component: <Example /> },
  ]

  return (
    <div style={{ padding: 40 }}>
      <NimbusTable
        options={exampleOptions}
        data={data}
        headers={headers}
        render={renderExample}
      />
    </div>
  )
}

export default TestTable
