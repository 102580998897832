import React from 'react'
import Menu2 from './Menu2'
import Header from './Header'
import '../assets/styles/layout.scss'
import Footer from './Footer'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadUser } from '../actions/auth'

const pkg = require('../../package.json')

function Layout2({ children }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadUser())
  }, [])
  return (
    <>
      <div
        className="container-fluid m-0 p-0 layout"
        style={{
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        <Header />
        <div className="row flex-nowrap layout-inside">
          <Menu2 />

          <div className="col-10 p-0 m-0">
            {children}
          </div>
        </div>
        <Footer version={pkg?.version} />
      </div>
    </>
  )
}

export default Layout2
