import React from 'react'
import { FaTrash } from 'react-icons/fa'
import './styles.css'

export default function DeleteButton({ action, name, id }) {
  const handleClick = () => {
    action(name)
  }
  return (
    <button className="btnDelete" name={name} id={id} onClick={handleClick}>
      <FaTrash />
    </button>
  )
}
