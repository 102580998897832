/* eslint-disable max-len */
import React from 'react'

const FlagIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29.89" height="30.373" viewBox="0 0 29.89 30.373">
    <path id="flag-icon" className="icon" data-name="Icon awesome-flag-checkered" d="M14.514,11.264V15.3c1.548.35,2.924.925,4.366,1.323V12.581c-1.542-.344-2.93-.919-4.366-1.317Zm13.246-7.3a17.321,17.321,0,0,1-6.94,1.892c-3.173,0-5.8-2.064-9.8-2.064a11.533,11.533,0,0,0-4.034.712,3.322,3.322,0,1,0-5,1.536v22.9A1.42,1.42,0,0,0,3.41,30.37h.949a1.42,1.42,0,0,0,1.424-1.424v-5.6a16.538,16.538,0,0,1,6.786-1.311c3.179,0,5.8,2.064,9.8,2.064a12.4,12.4,0,0,0,7.266-2.426,1.892,1.892,0,0,0,.819-1.566V5.688A1.9,1.9,0,0,0,27.759,3.968ZM10.148,19.307a18.687,18.687,0,0,0-4.366.985V16.11a16.97,16.97,0,0,1,4.366-1.032Zm17.463-7.978a18.921,18.921,0,0,1-4.366,1.418v4.217a11.025,11.025,0,0,0,4.366-1.542V19.6a9.585,9.585,0,0,1-4.366,1.607V16.964a10.033,10.033,0,0,1-4.366-.332v4a34.609,34.609,0,0,0-4.366-1.263V15.3a13.185,13.185,0,0,0-4.366-.225V10.926a20.932,20.932,0,0,0-4.366,1.24V7.984a16.987,16.987,0,0,1,4.366-1.3v4.247a10.09,10.09,0,0,1,4.366.338v-4A33.769,33.769,0,0,0,18.88,8.529v4.057a11.3,11.3,0,0,0,4.366.16V8.482a20.988,20.988,0,0,0,4.366-1.335Z" transform="translate(-0.563 0.003)" fill="#f5f5f5" />
  </svg>
)

export default FlagIcon
