import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import PhonePicker from '../../components/PhonePicker'
import TextArea from '../../components/TextArea'
import 'react-phone-input-2/lib/bootstrap.css'
import Button from '../../components/common/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getClients } from '../../actions/clients'
import { getServiceTypes } from '../../actions/serviceTypes'
import CheckBox from '../../components/common/CheckBox'
import { createServiceOrder, getServiceOrders } from '../../actions/serviceOrders'
import { useHistory } from 'react-router'

const formSchema = Yup.object().shape({
  Cliente: Yup.number().integer().required('Campo requerido'),
  Servicio: Yup.number().integer().required('Campo requerido'),
  Tipo: Yup.number().integer().required('Campo requerido'),
  Cantidad: Yup.number().integer().positive().required('Campo requerido'),
  Responsable: Yup.string().required('Campo requerido'),
  Phone: Yup.number().min(12).required('Campo requerido'),
  Referencias: Yup.string(),
  Fecha: Yup.date(),
  Hora: Yup.string(),
  Compra: Yup.number().integer().required('Campo requerido'),
  Cotizacion: Yup.number().integer().required('Campo requerido'),
  InfoAdicional: Yup.string(),
  Pago: Yup.string().required('Campo requerido'),
  NumFactura: Yup.number().integer(),
})

const initialValues = {
  Cliente: null,
  Servicio: 0,
  Urgent: false,
  Tipo: 0,
  Cantidad: 1,
  Responsable: '',
  Phone: null,
  Referencias: '',
  Fecha: new Date(),
  Hora: '',
  Compra: 0,
  Cotizacion: 0,
  InfoAdicional: '',
  Pago: 'EFECTIVO',
  NumFactura: 0,
}

export const ServiceOrdersForm = props => {
  const { edit, prefilledData } = props
  const clients = useSelector(state => state?.clients?.clients)
  const group = useSelector(state => state?.auth?.user?.default_group)
  const serviceTypes = useSelector(state => state?.serviceTypes?.elements)
  const history = useHistory()

  useEffect(() => {
    getClients()
    getServiceTypes()
  }, [])

  const handleSubmit = async (values, resetForm) => {
    let scheduled_at = `${values.Fecha}T${values.Hora}:00`
    if (!values?.Fecha || !values?.Hora) {
      scheduled_at = null
    }
    const formData = {
      client: values?.Cliente,
      services: [
        {
          service_type: parseInt(values?.Servicio, 10),
          asset: parseInt(values?.Tipo, 10),
          quantity: values?.Cantidad,
        }
      ],
      payment: {
        method: values?.Pago,
      },
      urgency: values?.Urgent,
      in_charge_name: values?.Responsable,
      location: values?.Referencias,
      quotation_number: values?.Cotizacion,
      purchase_order: values?.Compra,
      invoice_number: values?.Cotizacion,
      comments: values?.InfoAdicional,
      in_charge_phone: values?.Phone,
      group: group?.id,
      scheduled_at,
    }
    const success = await createServiceOrder(formData)
    if (success) {
      resetForm()
      getServiceOrders()
      history.push('/ordenes')
    }
  }

  const quantityInput = formikProps => {
    const selectedService = serviceTypes.find(st => st.id === parseInt(formikProps.values.Servicio, 10))
    const quantityTemplate = selectedService?.quantity_template
    return (
      <>
        <div className="col-4">
          <label>Cantidad</label>
          <Field type="number" name="Cantidad" className="form-control" />
          <ErrorMessage name="Cantidad" component="div" className="field-error text-danger" />
        </div>
        <div className="col-2">
          <label>Unidad</label>
          <Typography style={{ marginTop: 10, color: 'black' }}>{quantityTemplate}</Typography>
        </div>
      </>
    )

  }
  return (
    <Formik
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        await handleSubmit(values, resetForm)
        setSubmitting(false)
      }}
      initialValues={edit ? prefilledData : initialValues}
      validationSchema={formSchema}
      validateOnChange
      validateOnMount
    >
      {formikProps => (
        <div className="p-4 card" data-testid="service-orders-form">
          <Form>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>Cliente</label>
                  <Autocomplete
                    options={clients}
                    getOptionLabel={option => option?.name}
                    value={clients.find(c => c.id === formikProps.values.Cliente) || undefined}
                    onChange={(event, newValue) => formikProps.setValues(values => ({ ...values, Cliente: newValue?.id }))}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                  <ErrorMessage name="Cliente" component="div" className="field-error text-danger" />
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label>ID de Cliente</label>
                      <Field
                        disabled
                        type="number"
                        className="form-control"
                        value={clients.find(c => c.id === formikProps.values.Cliente)?.id || undefined}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label>Sucursal</label>
                      <Field
                        disabled
                        value={clients.find(c => c.id === formikProps.values.Cliente)?.group?.name || undefined}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label>Servicio</label>
                      <Field
                        onChange={formikProps.handleChange}
                        as="select"
                        type="number"
                        name="Servicio"
                        className="custom-select"
                      >
                        <option key="0" value={0}>Seleccione un servicio</option>
                        {serviceTypes.filter(s => s?.group?.id === group?.id)
                          .map(service => (
                            <option key={service?.id} value={parseInt(service?.id, 10)}>{service?.name}</option>
                          ))
                        }
                      </Field>
                      <ErrorMessage name="Servicio" component="div" className="field-error text-danger" />
                    </div>
                  </div>
                  <div className="col align-self-center">
                    <div className="form-check">
                      <CheckBox name="Urgent" value={formikProps.values.Urgent} onChange={formikProps.handleChange} className="form-check-input" />
                      <label>Servicio Urgente</label>
                      <ErrorMessage name="Urgent" component="div" className="field-error text-danger" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label>Tipo</label>
                      <Field
                        as="select"
                        name="Tipo"
                        type="number"
                        className="custom-select"
                        disabled={!serviceTypes.find(st => st.id === parseInt(formikProps.values.Servicio, 10))?.involves_asset}
                        onChange={formikProps.handleChange}
                      >
                        <option key="0" value={0}>Seleccione un contenedor</option>
                        {serviceTypes.find(st => st.id === parseInt(formikProps.values.Servicio, 10))?.asset_types
                          ?.map(t => (
                            <option key={t?.id} value={t?.id}>{t?.name}</option>
                          ))
                        }
                      </Field>
                      <ErrorMessage name="Tipo" component="div" className="field-error text-danger" />
                    </div>
                  </div>
                  {quantityInput(formikProps)}
                </div>
                <div className="form-group">
                  <label>Nombre del responsable</label>
                  <Field name="Responsable" className="form-control" type="text" />
                  <ErrorMessage name="Responsable" component="div" className="field-error text-danger" />
                </div>
                <div className="form-group">
                  <PhonePicker
                    label="Teléfono del responsable"
                    name="Phone"
                    value={formikProps.values.Phone}
                    handleChange={phone => formikProps.setFieldValue('Phone', phone)}
                  />
                </div>
                <div className="form-group">
                  <label>Dirección y referencias</label>
                  <TextArea name="Referencias" rows="6" />
                </div>

              </div>
              <div className="col">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label>Fecha de Servicio</label>
                      <Field name="Fecha" className="form-control" type="date" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label>Horario</label>
                      <Field name="Hora" className="form-control" type="time" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label>Orden de compra del cliente</label>
                      <Field name="Compra" className="form-control" type="text" />
                      <ErrorMessage name="Compra" component="div" className="field-error text-danger" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label>N° de cotización</label>
                      <Field name="Cotizacion" className="form-control" type="number" />
                      <ErrorMessage name="Cotizacion" component="div" className="field-error text-danger" />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Informacion adicional</label>
                  <TextArea name="InfoAdicional" rows="5" />
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label>Método de pago</label>
                      <Field as="select" name="Pago" className="custom-select">
                        <option value="EFECTIVO">Efectivo</option>
                        <option value="TARJETA">Tarjeta</option>
                        <option value="DEPOSITO">Depósito</option>
                        <option value="TRANSFERENCIA">Transferencia</option>
                        <option value="N/A">N/A</option>
                      </Field>
                      <ErrorMessage name="Pago" component="div" className="field-error text-danger" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label>N° de factura o remisión</label>
                      <Field name="NumFactura" className="form-control" type="text" />
                      <ErrorMessage name="NumFactura" component="div" className="field-error text-danger" />
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                  <Button
                    data-testid="submit-button"
                    type="submit"
                    variant="outlined"
                    disabled={!formikProps.isValid}
                  >
                    {edit ? 'Guardar' : 'Crear'}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  )
}
export default ServiceOrdersForm
