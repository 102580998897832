import React from 'react'
import { Snackbar as MuiSnackbar } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import MuiAlert from '@material-ui/lab/Alert'
import { CLOSE_SNACKBAR } from '../../actions/types'

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={10} ref={ref} {...props} />)

const Snackbar = ({ snackbar }) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch({ type: CLOSE_SNACKBAR })
  }

  return (
    <MuiSnackbar
      open={snackbar.open}
      autoHideDuration={snackbar ? snackbar.openSeconds : 5000}
      anchorOrigin={snackbar.vertical
        ? { vertical: snackbar.vertical, horizontal: snackbar.horizontal }
        : { vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={snackbar.severity} sx={{ width: '100%' }}>
        {snackbar ? snackbar.message : ''}
      </Alert>
    </MuiSnackbar>
  )
}

export default Snackbar
