import React from 'react'
import PropTypes from 'prop-types'
import EditButton from '../EditButton/EditButton'
import DeleteButton from '../DeleteButton/DeleteButton'
import MoreButton from '../MoreButton/MoreButton'
import './styles.css'
import { CheckBox } from '../../common/CheckBox'

export default function TableBody({
  data,
  keys,
  options,
  render,
}) {
  const {
    editAction,
    editButton,
    delAction,
    delButton,
    moreAction,
    moreButton,
    selectable,
  } = options

  return (
    <tbody>
      {data.map((element, index) => {
        const info = keys.map((key) => {
          let tuple
          if (render) tuple = render.find(r => r.index === key)
          if (tuple !== undefined) {
            return (
              <td key={index} className="table-td">
                {React.cloneElement(tuple.component, { [key]: element[key] })}
              </td>
            )
          }
          return (<td key={index} id={element.id}className="table-td">{element[key]}</td>)
        })
        return (
          <tr key={element?.id} className={index % 2 === 0 ? 'tuple-par tr-table' : 'tuple-non tr-table'}>
            {selectable ? (
              <td className="table-td">
                <CheckBox />
              </td>
            ) : null}
            {info}
            {editButton ? (
              <td className="table-td">
                <EditButton id={element.id} name={element.id} action={editAction} />
              </td>
            ) : null}
            {delButton ? (
              <td className="table-td">
                <DeleteButton id={element.id} name={element.id} action={delAction} />
              </td>
            ) : null}
            {moreButton ? (
              <td className="table-td" id={element.id}>
                <MoreButton id={element.id} name={element.id} action={moreAction} />
              </td>
            ) : null}
          </tr>
        )
      })}
    </tbody>
  )
}

TableBody.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.shape({
    selectable: PropTypes.bool,
    editButton: PropTypes.bool,
    delButton: PropTypes.bool,
    moreButton: PropTypes.bool,
    delAction: PropTypes.func,
    editAction: PropTypes.func,
    moreAction: PropTypes.func,
  }),
  render: PropTypes.arrayOf(PropTypes.object),
}

TableBody.defaultProps = {
  keys: [],
  data: [],
  render: [],
  options: {
    selectable: false,
    moreButton: false,
    editButton: false,
    delButton: false,
    moreAction: () => {},
    editAction: () => {},
    delAction: () => {},
  }
}
