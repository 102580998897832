import React from 'react'
import { GoPencil as MdEdit } from 'react-icons/go'
import './styles.css'

export default function EditButton({ action, name, id }) {
  const handleClick = () => {
    action(name)
  }
  return (
    <button name={name} className="btnEdit" id={id} onClick={handleClick}>
      <MdEdit />
    </button>
  )
}
