import React from 'react'
import PhoneInput from 'react-phone-input-2'

export default function PhonePicker({ value, label, handleChange, error }) {
  return (
    <>
      <label>{label}</label>
      <PhoneInput value={value} onChange={phone => handleChange(phone)} />
      {error
        ? <div className="field-error text-danger">Campo Requerido</div>
        : null}
    </>
  )
}
