import axios from 'axios'
import { GET_TEAMSTERS, ADD_TEAMSTER, DELETE_TEAMSTER, EDIT_TEAMSTER, API_FAIL, API_SUCCESS } from './types'
import { tokenHeader } from './auth'
import store from '../store'
import { parseErrors } from '../misc/parseAPIErrors'
import { createPaginatedActions } from './basePaginatedActions'

// GET TEAMSTERS
export const getTeamsters = async () => {
  let res
  try {
    res = await axios.get('api/teamsters/', tokenHeader(store.getState))
    store.dispatch({
      type: GET_TEAMSTERS,
      payload: res?.data,
    })
  } catch (error) {
    store.dispatch({
      type: API_FAIL,
      payload: String(error?.response?.data) || 'Unkwown error',
    })
  }
}

// ADD LOCAL TEAMSTER
export const addTeamster = (teamster) => {
  axios
    .post('/api/teamsters/', teamster, tokenHeader(store.getState))
    .then((res) => {
      store.dispatch({
        type: ADD_TEAMSTER,
        payload: res.data,
      })
      store.dispatch({
        type: API_SUCCESS,
        payload: `Operador creado: ${res.data.name}`,
      })
    })
    .catch((err) => store.dispatch({
      type: API_FAIL,
      payload: String(err?.response?.data),
    }))
}

// DELETE LOCAL TEAMSTER
export const deleteTeamster = (id) => (dispatch, getState) => {
  axios
    .delete(`/api/teamsters/${id}/`, tokenHeader(getState))
    .then(() => {
      dispatch({
        type: DELETE_TEAMSTER,
        payload: id,
      })
    })
    .catch((err) => console.error(err))
}

// EDIT LOCAL TEAMSTER
export const editTeamster2 = (teamster) => (dispatch, getState) => {
  axios
    .put(`/api/teamsters/${teamster.id}/`, teamster, tokenHeader(getState))
    .then((res) => {
      dispatch({
        type: EDIT_TEAMSTER,
        payload: res.data,
      })
    })
    .catch((err) => console.error(err))
}

export const editTeamster = async teamster => {
  let res
  try {
    res = await axios.patch(`/api/teamsters/${teamster.id}/`, teamster, tokenHeader(store.getState))
    store.dispatch({
      type: EDIT_TEAMSTER,
      payload: res.data,
    })
    store.dispatch({
      type: API_SUCCESS,
      payload: 'Operador guardado correctamente'
    })
    return true
  } catch (error) {
    store.dispatch({
      type: API_FAIL,
      payload: parseErrors(error?.response?.data) || 'Error desconocido. Contacte a soporte'
    })
    return false
  }
}

// PAGINATED ACTIONS
export const paginatedActions = createPaginatedActions(
  'TEAMSTERS',
  state => state.teamstersList,
  '/api/teamsters_list/',
)
