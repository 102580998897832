import React, { useState } from 'react'
import axios from 'axios'
import Main from '../components/Main'

const QRs = () => {
  const [interval, setInterval] = useState({ inf: null, sup: null })

  const handleChange = (e) => {
    setInterval({ ...interval, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // axios
    //     .post(`/api/qrs`, interval)
    //     .then(res => res.blob()).then(blob => {
    //         var fileName = "qrs.zip";
    //         saveAs(blob, fileName);
    //     })
    //     .catch( err => console.error(err))
    axios
      .request({
        url: '/api/qrs',
        method: 'POST',
        responseType: 'blob',
        data: interval,
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]))

        const link = document.createElement('a')

        link.href = downloadUrl

        link.setAttribute('download', `qr_${interval.inf}_${interval.sup}.zip`) // any other extension

        document.body.appendChild(link)

        link.click()

        link.remove()
      })
  }

  return (
    <Main>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column p-4">
          <label>Inicio</label>
          <input name="inf" onChange={handleChange} value={interval.inf} type="number" />
          <label>Fin</label>
          <input name="sup" onChange={handleChange} value={interval.sup} type="number" />
          <button type="submit" className="mt-4">Enviar</button>
        </div>
      </form>
    </Main>
  )
}

export default QRs
