/* eslint-disable max-len */
import React from 'react'

const RecycleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30.37" height="30.371" viewBox="0 0 30.37 30.371">
    <path id="recycle-icon" className="icon" data-name="Icon awesome-recycle" d="M10.948,15.535A.95.95,0,0,1,9.52,16.554L7.1,15.044,4.086,19.872A1.9,1.9,0,0,0,5.7,22.778H8.779a.712.712,0,0,1,.712.712v2.373a.712.712,0,0,1-.712.712H5.7A5.7,5.7,0,0,1,.872,17.862l3.015-4.828-2.416-1.51a.95.95,0,0,1,.289-1.73L8.3,8.285A.949.949,0,0,1,9.438,9l1.51,6.539Zm5.846-10.85L19.243,8.6l-2.417,1.51a.95.95,0,0,0,.289,1.73l6.539,1.51a.949.949,0,0,0,1.138-.711L26.3,6.1a.95.95,0,0,0-1.428-1.018L22.462,6.594l-2.448-3.92a5.7,5.7,0,0,0-9.658,0L9.291,4.38a.712.712,0,0,0,.227.981l2.012,1.257a.712.712,0,0,0,.981-.226l1.065-1.7A1.9,1.9,0,0,1,16.794,4.685ZM29.5,17.862l-1.632-2.614a.712.712,0,0,0-.981-.227l-2.009,1.255a.712.712,0,0,0-.226.981l1.635,2.617a1.9,1.9,0,0,1-1.61,2.9H18.982V19.932a.95.95,0,0,0-1.62-.671L12.616,24a.949.949,0,0,0,0,1.342l4.745,4.745a.95.95,0,0,0,1.62-.671V26.574h5.687A5.7,5.7,0,0,0,29.5,17.862Z" transform="translate(0 0)" fill="#f5f5f5" />
  </svg>
)

export default RecycleIcon
