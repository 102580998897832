import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Login from '../containers/Login'
import Layout2 from '../components/Layout2'
import Manifestos from '../containers/Manifestos'
import Clients from '../containers/Clients'
import Teamsters from '../containers/Teamsters'
import Trucks from '../containers/Trucks'
import PrivateRoute from '../components/common/PrivateRoute'
import { loadUser } from '../actions/auth'
import QRs from '../containers/QRs'
import TestData from '../containers/TestData'
import TestTable from '../containers/TestTable'
import Snackbar from '../components/common/Snackbar'
import NewOrder from '../containers/NewOrder'
import ServiceOrder from '../containers/ServiceOrder'
import { useEffect } from 'react'

const debugRoutes = process.env.NODE_ENV !== 'production' ? [
  <PrivateRoute key="tableExample" exact path="/newTableExample" component={TestTable} />,
  <PrivateRoute key="testComponent" exact path="/test" component={TestData} />,
] : []

const App = () => {
  const dispatch = useDispatch()
  const snackbar = useSelector((state) => state.snackbar)

  useEffect(() => {
    dispatch(loadUser())
  }, [])

  return (
    <>
      <Router>
        <Snackbar snackbar={snackbar} />
        <Switch>
          <Route key="login" exact path="/login" component={Login} />
          <Route key="slash" exact path="/">
            <Redirect to="/manifiestos" />
          </Route>
          <Layout2>
            <PrivateRoute key="orders" exact path="/ordenes" component={ServiceOrder} />
            <PrivateRoute key="ordersEdit" exact path="/ordenes/editar/:id" component={NewOrder} />
            <PrivateRoute key="ordersNew" exact path="/ordenes/nueva" component={NewOrder} />
            <PrivateRoute key="manifestos" exact path="/manifiestos" component={Manifestos} />
            <PrivateRoute key="clients" exact path="/clientes" component={Clients} />
            <PrivateRoute key="teamsters" exact path="/operadores" component={Teamsters} />
            <PrivateRoute key="trucks" exact path="/unidades" component={Trucks} />
            <PrivateRoute key="qr" exact path="/qr" component={QRs} />
            {debugRoutes.map(r => r)}
          </Layout2>
          <div />
        </Switch>
      </Router>
    </>
  )
}

export default App
