/* eslint-disable no-restricted-syntax */
import React from 'react'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useSelector } from 'react-redux'
import { addClient, editClient, getClients } from '../../actions/clients'
import Button from '../../components/common/Button'
import { TextInput } from '../../components/common/TextInput'
import DialogBlur from '../../components/common/DialogBlur'
import { makeStyles } from '@material-ui/styles'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { choices } from './stateChoices'
import { emailRegex } from '../../misc/utils'

const useStyles = makeStyles(() => ({
  dialogTitle: {
    backgroundColor: '#F58B00',
    color: 'white',
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
  },
  content: {
    dividers: true,
    overflow: 'hidden',
    marginTop: 20,
    marginBottom: 20,
  },
}))

export default function ClientFormDialog({ open, setOpen, client, mode, id }) {
  const styles = useStyles()
  const user = useSelector((state) => state.auth.user)

  const initialState = mode === 'edit' ? client : {
    name: '',
    address: '',
    legal_form: '',
    amb_reg_no: 'EN TRAMITE',
    zip_code: '',
    city: '',
    state: '',
    phone: '',
    email: '',
    is_in_charge: '',
    owner: user?.id,
    group: user?.default_group?.id,
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handlePost = async values => {
    let success
    if (mode === 'create') {
      success = await addClient(values)
    } else if (mode === 'edit') {
      values.owner = values?.owner?.id
      values.group = values?.group?.id
      success = await editClient(values, id)
    }
    if (success) {
      await getClients()
      handleClose()
    }
  }

  return (
    <>
      <DialogBlur
        open={open}
        onClose={handleClose}
      >
        <Formik
          onSubmit={handlePost}
          initialValues={initialState}
          validationSchema={
            yup.object({
              name: yup.string('Nombre del cliente').required('Este campo es requerido'),
              address: yup.string('Dirección').required('Este campo es requerido'),
              legal_form: yup.string('Razón social').required('Este campo es requerido'),
              amb_reg_no: yup.string('Número de registro ambiental').required('Este campo es requerido'),
              zip_code: yup.string()
                .test('len', 'El código postal debe tener exactamente 5 dígitos', val => val.length === 5)
                .required('Este campo es requerido'),
              city: yup.string('Ciudad').required('Este campo es requerido'),
              state: yup.string('Estado').required('Este campo es requerido'),
              phone: yup.string()
                .max(10, 'Máximo 10 digitos')
                .min(7, 'Al menos 7 digitos')
                .required('Este campo es requerido'),
              email: yup.string('Email')
                .matches(emailRegex, 'Formato de e-mail incorrecto')
                .required('Este campo es requerido'),
              is_in_charge: yup.string('En cargo').required('Este campo es requerido'),
            })
          }
          validateOnChange
          validateOnMount
        >
          {formikProps => (
            <>
              <DialogTitle id="form-dialog-title" className={styles.dialogTitle}>
                {mode === 'create' ? 'Crear nuevo cliente' : 'Editar cliente'}
              </DialogTitle>
              <DialogContent className={styles.content}>
                <DialogContentText>
                  {mode === 'create' ? 'Llene los siguientes campos para crear un nuevo cliente.' : ' '}
                </DialogContentText>
                <Form noValidate onBlur={formikProps.handleBlur} onSubmit={formikProps.handleSubmit}>
                  <TextInput
                    id="name"
                    name="name"
                    label="Nombre"
                    type="text"
                    value={formikProps.values.name}
                    onChange={formikProps.handleChange}
                    error={formikProps.errors.name && formikProps.touched.name}
                    helperText={formikProps.touched.name && formikProps.errors.name}
                    fullWidth
                  />
                  <TextInput
                    id="address"
                    name="address"
                    label="Dirección"
                    type="text"
                    value={formikProps.values.address}
                    onChange={formikProps.handleChange}
                    error={formikProps.errors.address && formikProps.touched.address}
                    helperText={formikProps.touched.address && formikProps.errors.address}
                    fullWidth
                  />
                  <TextInput
                    id="legalForm"
                    name="legal_form"
                    label="Razón social"
                    type="text"
                    value={formikProps.values.legal_form}
                    onChange={formikProps.handleChange}
                    error={formikProps.errors.legal_form && formikProps.touched.legal_form}
                    helperText={formikProps.touched.legal_form && formikProps.errors.legal_form}
                    fullWidth
                  />
                  <TextInput
                    id="ambRegNo"
                    name="amb_reg_no"
                    label="No. registro ambiental"
                    type="text"
                    value={formikProps.values.amb_reg_no}
                    onChange={formikProps.handleChange}
                    defaultValue={'EN TRAMITE'}
                    error={formikProps.errors.amb_reg_no && formikProps.touched.amb_reg_no}
                    helperText={formikProps.touched.amb_reg_no && formikProps.errors.amb_reg_no}
                    fullWidth
                  />
                  <TextInput
                    label="C.P."
                    name="zip_code"
                    type="text"
                    value={formikProps.values.zip_code}
                    onChange={formikProps.handleChange}
                    error={formikProps.errors.zip_code && formikProps.touched.zip_code}
                    helperText={formikProps.touched.zip_code && formikProps.errors.zip_code}
                    fullWidth
                  />
                  <TextInput
                    label="Ciudad"
                    name="city"
                    type="text"
                    value={formikProps.values.city}
                    onChange={formikProps.handleChange}
                    error={formikProps.errors.city && formikProps.touched.city}
                    helperText={formikProps.touched.city && formikProps.errors.city}
                    fullWidth
                  />
                  <Autocomplete
                    name="state"
                    options={choices}
                    getOptionLabel={o => choices.find(c => c.value === o)?.displayName || o?.displayName}
                    onChange={(e, { value }) => {
                      formikProps.setValues(values => ({ ...values, state: value }))
                    }}
                    value={formikProps.values.state}
                    fullWidth
                    renderInput={params => (
                      <TextInput
                        {...params}
                        label="Estado"
                        name="state"
                        error={formikProps.errors.state && formikProps.touched.state}
                        helperText={formikProps.touched.state && formikProps.errors.state}
                      />
                    )}
                  />
                  <TextInput
                    name="phone"
                    label="Teléfono"
                    type="text"
                    value={formikProps.values.phone}
                    onChange={formikProps.handleChange}
                    error={formikProps.errors.phone && formikProps.touched.phone}
                    helperText={formikProps.touched.phone && formikProps.errors.phone}
                    fullWidth
                  />
                  <TextInput
                    name="email"
                    label="Email"
                    type="email"
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange}
                    error={formikProps.errors.email && formikProps.touched.email}
                    helperText={formikProps.touched.email && formikProps.errors.email}
                    fullWidth
                  />
                  <TextInput
                    name="is_in_charge"
                    label="En Cargo"
                    type="text"
                    value={formikProps.values.is_in_charge}
                    onChange={formikProps.handleChange}
                    error={formikProps.errors.is_in_charge && formikProps.touched.is_in_charge}
                    helperText={formikProps.touched.is_in_charge && formikProps.errors.is_in_charge}
                    fullWidth
                  />
                </Form>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="outlined" style={{ color: 'red', borderColor: 'red' }}>
                  Cancelar
                </Button>
                <Button type="submit" onClick={formikProps.handleSubmit} variant="outlined" disabled={!formikProps.isValid}>
                  {mode === 'create' ? 'Crear ' : 'Editar'}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </DialogBlur>
    </>
  )
}
