import React from 'react'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core'

const colors = {
  PENDIENTE: '#8F9201',
  COMPLETADO: '#107318',
  CANCELADO: '#730000',
}

const bgColors = {
  PENDIENTE: '#FAECA6',
  COMPLETADO: '#C8FEC9',
  CANCELADO: '#F63838',
}

const ChipComponent = ({ label }) => {
  const StyledChip = withStyles({
    root: {
      backgroundColor: bgColors[label],
      // height: 0,
      color: colors[label],
      fontWeight: 'bold',
    },
  })(Chip)

  return (
    <StyledChip label={`● ${label}`} />
  )
}

export default ChipComponent
