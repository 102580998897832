// Auth
export const USER_LOADING = 'USER_LOADING'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

// Clients
export const GET_CLIENTS = 'GET_CLIENTS'
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const ADD_CLIENT = 'ADD_CLIENT'
export const EDIT_CLIENT = 'EDIT_CLIENT'

// Manifestos
export const GET_MANIFESTOS = 'GET_MANIFESTOS'
export const DELETE_MANIFESTO = 'DELETE_MANIFESTO'
export const ADD_MANIFESTO = 'ADD_MANIFESTOS'
export const EDIT_MANIFESTO = 'EDIT_MANIFESTOS'

// Teamsters
export const GET_TEAMSTERS = 'GET_TEAMSTERS'
export const DELETE_TEAMSTER = 'DELETE_TEAMSTER'
export const ADD_TEAMSTER = 'ADD_TEAMSTER'
export const EDIT_TEAMSTER = 'EDIT_TEAMSTER'

// Trucks
export const GET_LOCAL_TRUCKS = 'GET_LOCAL_TRUCKS'
export const DELETE_LOCAL_TRUCK = 'DELETE_LOCAL_TRUCK'
export const ADD_LOCAL_TRUCK = 'ADD_LOCAL_TRUCK'
export const EDIT_LOCAL_TRUCK = 'EDIT_LOCAL_TRUCK'

// Transports
export const GET_TRANSPORTS = 'GET_TRANSPORTS'
export const ADD_TRANSPORT = 'ADD_TRANSPORTS'
export const EDIT_TRANSPORT = 'EDIT_TRANSPORT'

// Containers
export const GET_CONTAINERS = 'GET_CONTAINERS'
export const ADD_CONTAINER = 'ADD_CONTAINER'

// Disposal Types
export const GET_DISPOSAL_TYPES = 'GET_DISPOSAL_TYPES'
export const ADD_DISPOSAL_TYPE = 'ADD_DISPOSAL_TYPE'

// Service Orders
export const GET_SERVICE_ORDERS = 'GET_SERVICE_ORDERS'
export const ADD_SERVICE_ORDER = 'ADD_SERVICE_ORDER'

// Snackbar
export const API_SUCCESS = 'API_SUCCESS'
export const API_FAIL = 'API_FAIL'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'

// Service Types
export const GET_SERVICE_TYPES = 'GET_SERVICE_TYPES'
