import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  backDrop: {
    backdropFilter: 'blur(1px)',
  },
  paper: {
    borderRadius: 20,
  },
}))

const DialogBlur = (props) => {
  const styles = useStyles()
  return (
    <Dialog
      classes={{
        paper: styles.paper,
      }}
      BackdropProps={{ classes: { root: styles.backDrop } }}
      {...props}
    />
  )
}

export default DialogBlur

DialogBlur.defaultProps = {
  scroll: 'body'
}
