import { GET_SERVICE_ORDERS } from '../actions/types'

const initialState = {
  serviceOrders: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
  case GET_SERVICE_ORDERS:
    return {
      ...state,
      serviceOrders: action.payload,
    }
  default:
    return state
  }
}
