import React from 'react'
import PropTypes from 'prop-types'
import './styles.css'

export default function TableHeader({
  headers,
  tableStyle,
  options
}) {
  const {
    editButton,
    delButton,
    moreButton,
    selectable,
  } = options

  return (
    <thead className="header">
      <tr style={{borderRadius: '20px 20px 0 0'}}>
        {selectable
          ? <th>SELECT</th>
          : null}
        {headers.map((element) => (
          <th key={element?.title} className={tableStyle}>
            {element.title.toUpperCase()}
          </th>
        ))}

        {editButton ? <th /> : null}
        {delButton ? <th /> : null}
        {moreButton ? <th /> : null}
      </tr>
    </thead>
  )
}

TableHeader.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.shape({
    editButton: PropTypes.bool,
    moreButton: PropTypes.bool,
    delButton: PropTypes.bool,
    selectable: PropTypes.bool,
  }),
  tableStyle: PropTypes.string,
}

TableHeader.defaultProps = {
  options: {
    selectable: false,
    moreButton: false,
    editButton: false,
    delButton: false,
  },
  tableStyle: '',
}
