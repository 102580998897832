import { combineReducers } from 'redux'
import clients from './clients'
import manifestos from './manifestos'
import auth from './auth'
import teamsters from './teamsters'
import transports from './transports'
import teamstersList from './teamstersList'
import serviceOrdersList from './serviceOrdersList'
import containers from './containers'
import disposalTypes from './disposalTypes'
import serviceOrders from './serviceOrders'
import snackbar from './snackbar'
import serviceTypes from './serviceTypes'
import manifestosList from './manifestosList'
import clientsList from './clientsList'

export default combineReducers({
  snackbar,
  clients,
  clientsList,
  auth,
  manifestos,
  manifestosList,
  teamsters,
  teamstersList,
  transports,
  containers,
  serviceOrders,
  serviceOrdersList,
  disposalTypes,
  serviceTypes,
})
