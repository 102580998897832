import React from 'react'
import { useField } from 'formik'

export default function TextArea({ label, ...props }) {
  const [field, meta] = useField(props)
  return (
    <>
      <label>{label}</label>
      <textarea className="form-control" onChange={(e) => console.log(e)} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="field-error text-danger">{meta.error}</div>
      ) : null}
    </>
  )
}
