import React from 'react'

function MenuElement2({ title, Icon, selected }) {
  return (
    <li
      style={{ width: '100%' }}
      className={`nav-item p-2 menu-element ${selected ? 'selected' : ''}`}
    >
      <div
        className="d-flex nav-link align-middle"
      >
        <div className="ml-2 icon mr-2">
          <Icon />
        </div>
        <span className={`ms-1 d-none d-sm-inline text-uppercase ${selected ? 'selected' : ''}`}>
          {title}
        </span>
      </div>
    </li>
  )
}

export default MenuElement2
