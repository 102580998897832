import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import ChipComponent from '../../components/ChipComponent'

const styles = {
  root: {
  },
  header: {
    padding: '18px',
    backgroundColor: '#F58B00',
    color: 'white',
  },
  section: {
    padding: '18px',
  },
  footer: {
    padding: '5px 18px 30px 18px',
  },
  typography: {
    color: 'black',
  }
}

const ServiceOrderDialog = ({ open, onClose, data, classes }) => {
  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          borderRadius: '20px',
        },
      }}
    >
      <Grid
        container
        className={classes.header}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item={6}>
          <h2>{`Orden de Servicio: #${String(data?.id).padStart(4, '0')}`}</h2>
        </Grid>
        <Grid item={6}>
          <ChipComponent label={data?.status} />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.section}
      >
        <Grid container item xs={4}>
          <Grid item xs={3}>
            <Typography className={classes.typography} variant="subtitle1">
							Cliente:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography className={classes.typography} variant="subtitle1">
              {data?.client?.legal_form}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={8}>
          <Grid item xs={4}>
            <Typography className={classes.typography} variant="subtitle1">
							Dirección:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.typography} variant="subtitle1">
              {`${data?.client?.address}
							C.P. ${data?.client?.zip_code}. ${data?.client?.city}, ${data?.client?.state}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={4}>
          <Grid item xs={3}>
            <Typography className={classes.typography} variant="subtitle1">
							Teléfono:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography className={classes.typography} variant="subtitle1">
              {data?.client?.phone}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={8}>
          <Grid item xs={4}>
            <Typography className={classes.typography} variant="subtitle1">
							No. Registro Ambiental:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.typography} variant="subtitle1">
              {data?.client?.amb_reg_no}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1}>
            <Typography className={classes.typography} variant="subtitle1">
							Email:
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography className={classes.typography} variant="subtitle1">
              {data?.client?.email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      {/* <h5>Payment Details</h5> */}
      <Grid container className={classes.section}>
        <Grid container item xs={12}>
          <Grid item xs={2}>
            <Typography className={classes.typography} variant="caption">
							Cant.
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography className={classes.typography} variant="caption">
							Descripción
            </Typography>
          </Grid>
          <Grid item xs={3} style={{ display: 'flex' }}>
            <Typography className={classes.typography} variant="caption">
							Costo
            </Typography>
          </Grid>
        </Grid>
        {data?.services?.map((service) => (
          <Grid key={service?.id} container item xs={12}>
            <Grid item xs={2}>
              <Typography className={classes.typography} variant="body1">
                {service?.quantity}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.typography} variant="body1">
                {
                  service?.asset_type?.name ?
                    `${service?.service_type?.name} - ${service?.asset_type?.name}`
                    : service?.service_type?.name
                }
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.typography} variant="body1">
                $
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid
          container
          item
          style={{ paddingTop: '40px' }}
          justifyContent="flex-end"
        >
          <Grid item xs={7} />
          <Grid item xs={1}>
            <Typography className={classes.typography} variant="body1">
							Subtotal
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Grid container>
              <Grid item xs={3}>
                <Typography className={classes.typography} variant="body1">
									$
                </Typography>
              </Grid>
              <Grid item xs={9} style={{ display: 'flex' }}>
                <Typography className={classes.typography} variant="body1">
									16,0000.00 MXN
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          style={{ paddingTop: '5px' }}
          justifyContent="flex-end"
        >
          <Grid item xs={7} />
          <Grid item xs={1}>
            <Typography className={classes.typography}
              variant="body1"
            >
							IVA
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Grid container>
              <Grid item xs={3}>
                <Typography className={classes.typography} variant="body1">
									$
                </Typography>
              </Grid>
              <Grid item xs={9} style={{ display: 'flex' }}>
                <Typography className={classes.typography} variant="body1">
                  { data?.payment?.total * 0.16 }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          style={{ paddingTop: '5px' }}
          justifyContent="flex-end"
        >
          <Grid item xs={7} />
          <Grid item xs={1}>
            <Typography className={classes.typography}
              variant="body1"
              style={{ fontWeight: 'bold' }}
            >
							Total
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Grid container>
              <Grid item xs={3}>
                <Typography className={classes.typography} variant="body1" style={{ fontWeight: 'bold' }}>
									$
                </Typography>
              </Grid>
              <Grid item xs={9} style={{ display: 'flex' }}>
                <Typography className={classes.typography} variant="body1" style={{ fontWeight: 'bold' }}>
                  {`${data?.payment?.total * 1.16} MXN`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid
					container
					item
					style={{ paddingTop: '5px' }}
					justifyContent="flex-end"
				>
					<Grid item xs={7} />
					<Grid item xs={1}>
						<Typography className={classes.typography} variant="body1">
							Pagado
						</Typography>
					</Grid>
					<Grid item xs={3}>
						<Grid container>
							<Grid item xs={3}>
								<Typography className={classes.typography} variant="body1">
									$
								</Typography>
							</Grid>
							<Grid item xs={9} style={{ display: 'flex' }} justifyContent="flex-end">
								<Typography className={classes.typography} variant="body1">
									0.00 MXN
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid> */}
      </Grid>
      <Divider variant="middle" />
      <Grid
        container
        className={classes.footer}
        justifyContent="flex-end"
      >
        {/* <Grid item xs={7} />
				<Grid item xs={1}>
					<Typography className={classes.typography} variant="body1">
						Restante
					</Typography>
				</Grid>
				<Grid item xs={3}>

					<Grid container>
						<Grid item xs={3}>
							<Typography className={classes.typography} variant="body1">
								$
							</Typography>
						</Grid>
						<Grid item xs={9} style={{ display: 'flex' }} justifyContent="flex-end">
							<Typography className={classes.typography} variant="body1">
								0.00 MXN
							</Typography>
						</Grid>
					</Grid>
				</Grid> */}
      </Grid>
    </Dialog>
  )
}

export default withStyles(styles)(ServiceOrderDialog)
