import React from 'react'
import '../assets/styles/components/Menu2.scss'
import { Link, useLocation } from 'react-router-dom'
import RecycleIcon from './icons/RecycleIcon'
import ClientIcon from './icons/ClientIcon'
import OperatorIcon from './icons/OperatorIcon'
import TruckIcon from './icons/TruckIcon'
import FlagIcon from './icons/FlagIcon'
import MenuElement2 from './MenuElement2'
import { useSelector } from 'react-redux'

function Menu2() {
  const flags = useSelector(state => state?.auth?.user?.flags) || []
  const location = useLocation()

  return (
    <div className="d-flex flex-column col-2 p-0 menu2">
      <div className="d-flex flex-column align-items-center align-items-sm-start text-white ">
        <ul
          className="nav flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start p-0"
          id="menu"
          style={{ width: '100%' }}
        >
          <Link style={{ width: '100%' }} to="/ordenes">
            <MenuElement2
              Icon={FlagIcon}
              title="Ordenes de Servicio"
              selected={location.pathname.includes('ordenes')}
            />
          </Link>
          <Link style={{ width: '100%' }} to="/manifiestos">
            <MenuElement2
              Icon={RecycleIcon}
              title="Manifestos"
              selected={location.pathname === '/manifiestos'}
            />
          </Link>
          <Link style={{ width: '100%' }} to="/clientes">
            <MenuElement2
              Icon={ClientIcon}
              title="Clientes"
              selected={location.pathname === '/clientes'}
            />
          </Link>
          <Link style={{ width: '100%' }} to="/operadores">
            <MenuElement2
              Icon={OperatorIcon}
              title="Operadores"
              selected={location.pathname === '/operadores'}
            />
          </Link>
          {flags.includes('ASSETS_CRUD') ?
            <Link style={{ width: '100%' }} to="/unidades">
              <MenuElement2
                Icon={TruckIcon}
                title="Unidades"
                selected={location.pathname === '/unidades'}
              />
            </Link> : null
          }
          {flags.includes('QR_CODES') ?
            <Link style={{ width: '100%' }} to="/qr">
              <MenuElement2
                Icon={FlagIcon}
                title="QR"
                selected={location.pathname === '/qr'}
              />
            </Link> : null
          }
        </ul>
      </div>
      <div style={{ backgroundColor: '#79767a', height: '100%', maxHeight: '100%' }} />
    </div>
  )
}

export default Menu2
