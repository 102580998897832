import axios from 'axios'
import { GET_MANIFESTOS, DELETE_MANIFESTO, ADD_MANIFESTO, EDIT_MANIFESTO } from './types'
import { tokenHeader } from './auth'
import { createPaginatedActions } from './basePaginatedActions'
// axios.defaults.xsrfHeaderName = "X-CSRF-TOKEN";
// axios.defaults.withCredentials = true;

// GET MANIFESTOS
export const getManifestos = () => (dispatch, getState) => {
  axios
    .get('api/manifestos/', tokenHeader(getState))
    .then((res) => {
      dispatch({
        type: GET_MANIFESTOS,
        payload: res.data,
      })
    })
    .catch((err) => console.error(err))
}

// ADD MANFIESTO
export const addManifesto = (manifesto) => (dispatch, getState) => {
  axios
    .post('/api/manifestos/', manifesto, tokenHeader(getState))
    .then((res) => {
      dispatch({
        type: ADD_MANIFESTO,
        payload: res.data,
      })
    })
    .catch((err) => console.error(err))
}

export const editManifesto = (manifesto) => (dispatch, getState) => {
  axios
    .put(`/api/manifestos/${manifesto.id}/`, manifesto, tokenHeader(getState))
    .then((res) => {
      dispatch({
        type: EDIT_MANIFESTO,
        payload: res.data,
      })
    })
    .catch((err) => console.error(err))
}

export const deleteManifesto = (id) => (dispatch, getState) => {
  axios
    .delete(`/api/manifestos/${id}/`, tokenHeader(getState))
    .then(() => {
      dispatch({
        type: DELETE_MANIFESTO,
        payload: id,
      })
    })
    .catch((err) => console.error(err))
}

// PAGINATED ACTIONS
export const paginatedActions = createPaginatedActions(
  'MANIFESTOS',
  state => state.manifestosList,
  '/api/manifestos_list/',
)
